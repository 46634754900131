import React from 'react'
import {Modal} from "react-bootstrap";

class ResponseModal extends React.Component {
  constructor() {
    super();
    this.state = {
      openResponse: false,
      response: ''
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({openResponse: props.open, response: props.response});
  }

  openModal() {
    this.setState({openResponse: true});
  }

  closeModal() {
    this.setState({openResponse: false});
  }

  render() {
    return (<Modal id="response" show={this.state.openResponse} onHide={this.closeModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered="centered">
      <Modal.Header>
        <button type="button" className="close p-0" style={{
            opacity: '1'
          }} onClick={this.closeModal}>
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
            <g filter="url(#filter0_d)">
              <circle cx="28" cy="26" r="18" fill="white"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.0455 28.0182L24.122 31.943L22 29.822L25.851 25.971L22.001 22.121L24.122 20L28.0475 23.9248L31.971 20L34.093 22.121L30.242 25.972L34.092 29.822L31.971 31.943L28.0455 28.0182Z" fill="#A7A7A7"/>
            <defs>
              <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
            </defs>
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <span className="h5">{this.state.response}</span>
      </Modal.Body>
    </Modal>);
  }
}

export default ResponseModal
