import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

// Import Pages
import Home from './Pages/Home';
import About from './Pages/About';
import Careers from './Pages/Careers';
import Features from './Pages/Features';
import Blog from './Pages/Blog';
import Post from './Pages/Blog-post';
import FAQ from './Pages/FAQ';
import Login from './Pages/Login';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Retail from './Pages/Industry_Retail';
import Agriculture from './Pages/Industry_Agriculture';
import FMCG from './Pages/Industry_FMCG';
import Facilities from './Pages/Industry_Facilities';
import FB from './Pages/Industry_F&B';

class App extends Component {
  render() {
    return (<Router >
      <ScrollToTop>
      <Route exact path="/" component={Home}/>
      <Route path="/about-us" component={About}/>
      <Route path="/features" component={Features}/>
      <Route path="/careers" component={Careers}/>
      <Route exact path="/blog" component={Blog}/>
      <Route path="/blog/:post" component={Post}/>
      <Route path="/faq" component={FAQ}/>
      <Route path="/login" component={Login}/>
      <Route path="/terms" component={Terms}/>
      <Route path="/privacy" component={Privacy}/>
      <Route path="/industry/retail" component={Retail}/>
      <Route path="/industry/agriculture" component={Agriculture}/>
      <Route path="/industry/fmcg" component={FMCG}/>
      <Route path="/industry/facilities-management" component={Facilities}/>
      <Route path="/industry/food-&-beverages" component={FB}/>
      </ScrollToTop>
    </Router>)
  }
}

export default App;
