import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Image
} from 'react-bootstrap';
import { Link } from "react-scroll";
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'

// Utilities
import Slider from "react-slick";

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var axios = require('axios');

class FacilityPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      productsList: [], // Products
      brandsList: [], // Brands
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  componentDidMount() {
    // Products JSON
    axios.get('../Data/features_Facility.json').then(response => {
      this.setState({productsList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
    // Brands JSON
    axios.get('../Data/brands.json').then(response => {
      this.setState({brandsList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  render() {

    /*
    Brands Slider
    */
    const brandsSlider = {
      autoplay: true,
      dots: true,
      infinite: true,
      arrows: false,
      centerMode: false,
      speed: 800,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: 'slider-brands',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            autoplay: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            dots: false,
          }
        },
          {
            breakpoint: 500,
            settings: {
              autoplay: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              dots: false,
            }
          }
      ]
    };

    // Brands List
    const brandsList = this.state.brandsList;
    let brandsListBlock = '';

    if (brandsList.length > 0) {
      brandsListBlock = brandsList.filter((obj) => {
        return obj.cat === "Reference"
      }).map(function(obj,i) {
        return (<div key={i}><img src={obj.icon} alt={obj.name}/></div>)
      });
    }

    /*
    Products
    */
    const productsList = this.state.productsList;
    let productsListBlock = '';

    if (productsList.length > 0) {
      productsListBlock = productsList.map((obj, i) => {
        return (<Col lg={4} className="slider-feature text-center" key={i}>
          <figure className="image">
            <img src={obj.icon} alt={obj.title}/>
          </figure>
          <h3 className="h5 text-center mb-3 text-dark">
            {obj.title}
          </h3>
          <p className="text-center">
            {obj.description}
          </p>
          <NavLink to="/features" className="text-left btn btn-outline-green nimbled pr-4 pl-4">Learn More</NavLink>
        </Col>)
      })
    }

    return (<div className="main">
      <Helmet>
        <title>Nimbly - Facility Management</title>
        <meta name="description" content="Digital Agribusiness Management in Real-Time"/>
      </Helmet>

      <Header className="header"/>

      <section className="pb-4 pb-lg-5 mb-5 industries--bg industries--bg-facilities" style={{'backgroundRepeatY':'no-repeat'}}>
        <Container className="pt-lg-5 mt-lg-5">
          <Row>
            <Col lg={6} className="pr-lg-5 text-center text-lg-right">
              <figure className="">
                <Image src="/Assets/Images/Industries/Facility Management/iStock-1097682848.jpg" alt="Facility Management" width="100%"  roundedCircle="roundedCircle" className="object-fit_cover boxed-image" />
              </figure>
            </Col>
            <Col lg={6} className="pl-lg-5">
            <h2 className="h5 mb-5 mt-4 mt-lg-0 text-center text-lg-left underline-center underline-lg-left underline-black">Facility Management</h2>
            <h1 className="text-center text-lg-left">Digital Facility Management
in Real-Time</h1>
            <p className="mt-4 mb-5 text-gray text-center text-lg-left" style={{'fontWeight':'500'}}>Monitor assets effectively with streamlined operations management.</p>
            <Col sm={12} xl={9} className="text-white text-center text-lg-left d-flex justify-content-around btn-block flex-column flex-lg-row">
              <Button variant="outline-purple" className="nimbled btn-block mr-3 justify-content-center mb-4" onClick={this.openModal}>
                How it works?
              </Button>
              <Link to="demo" spy={true} smooth={true} offset={-70} duration= {500} className="nimbled d-lg-inline-block btn-block justify-content-center btn btn-purple mb-4">
                Get started now!</Link>
            </Col>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-4 pb-5 mt-lg-5 ">
        <Container>
          <Row>
            <Col xl={6}>
              <div className="infobox-container">
                <figure className="infobox-image">
                  <img className="object-fit_cover" src="/Assets/Images/Industries/Facility Management/iStock-962599852.jpg" width="100%" height="100%" alt="bg1"/>
                </figure>
                <div className="infobox-content">
                  <h2 className="h5 infobox-content--title mb-5 underline-left underline-yellow text-green">
                    Gain visibility on issues and correction plan
                  </h2>
                  <p className="infobox-content--desc">
Make sure your company report and track every single issue to prevent major incidents. Nimbly enables you to flag issues, distribute reports, and assign departments just in a handheld device. Track your asset maintenance from planning to the completion.
                   </p>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="infobox-container">
                <figure className="infobox-image">
                  <img className="object-fit_cover" src="/Assets/Images/Industries/Facility Management/iStock-668388082.jpg" width="100%" height="100%" alt="bg1"/>
                </figure>
                <div className="infobox-content">
                  <h2 className="h5 infobox-content--title mb-5 underline-left underline-yellow text-green">
                    Schedule asset audits easily
                  </h2>
                  <p className="infobox-content--desc">
Schedule assessments and communicate risks in half the time. Nimbly speeds up data collection and helps your organisation gain breakthrough efficiency. Get data analytics and smart suggestions provided by Nimbly. Start seeing new sustainable design opportunity and produce large reductions in operational expenditures.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <div className="infobox-container">
                <figure className="infobox-image--long">
                  <img className="object-fit_cover" src="/Assets/Images/Industries/Facility Management/iStock-1162152536.jpg" width="100%" height="100%" alt="bg3"/>
                </figure>
                <div className="infobox-content">
                  <h2 className="h5 infobox-content--title mb-5 underline-left underline-yellow text-green">
                    Commitment to safety and policy compliance
                  </h2>
                  <p className="infobox-content--desc">
Nimby enables your team to do health and safety audits efficiently. Generate and distribute audit reports automatically. Streamline and scale your facility assessment process with Nimbly. Its customizability enables specialised audit checklist for team members to collect and verify data within various roles. Perform cross-checks and maximize compliance assessment with Nimbly digital audit.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-lg-5 mt-lg-5 mb-lg-2">
      </section>

      <section className="pb-5 mt-5 mb-2">
        <Container className="mt-lg-4">
        <h2 className="h5 mb-5 underline-center underline-yellow text-center">Facility Management companies who love using Nimbly</h2>
          <Slider {...brandsSlider}>
            {brandsListBlock}
          </Slider>
        </Container>
      </section>

      <section className="mt-5 pt-lg-3 mt-lg-3 pb-5 mb-5">
        <Container className="mt-lg-5 pt-lg-5">
          <h2 className="h5 text-center underline-center underline-yellow">Product Features</h2>
          <Row className="products-features">
            {productsListBlock}
          </Row>
        </Container>
      </section>

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default FacilityPage;
