import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Helmet} from 'react-helmet'

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

class privacyPage extends Component {
  render() {
    return (<div className="main faq">
      <Helmet>
        <title>Nimbly - Privacy Policy</title>
        <meta name="description" content="Privacy Policy"/>
      </Helmet>

      <Header className="header"/>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 mt-lg-5 faq--header">
        <Container className="">
          <Row>
            <Col className="pl-xl-5">
              <div className="faq-box">
                <div className="">
                  <h1 className="h4 mb-4 mb-lg-0 underline-center underline-white text-white text-center">Privacy Policy</h1>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 mt-3 pt-lg-3">
        <Container className="pt-lg-4">
          <Row>
            <Col lg={8} className="offset-lg-2">
              <div className="sc-eqIVtm gMyART">
                <p>
                  By using the Nimbly mobile app and content management system (“Service”), or any services of Nimbly Technologies, Pte. Ltd. (“Nimbly”), you are agreeing to be bound by the following terms and conditions (“Terms of Service”). Violation of any of the terms below will result in the termination of your account. Nimbly reserves the right to update and change the Terms of Service from time to time. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute your consent to such changes.
                </p>
                <h2>Account Terms</h2>
                <ol>
                  <li>
                    You must provide your organization’s legal full name, a valid email address, and any other information requested in order to complete the signup process.</li>
                  <li>You must be a human. Accounts registered by “bots” or other automated methods are not permitted.</li>
                  <li>You are responsible for maintaining the security of your account and password. Nimbly cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
                  <li>You are responsible for all Content posted and activity that occurs under your account.</li>
                  <li>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
                </ol>
                <h2>Cancellation and Termination</h2>
                <ol>
                  <li>If you cancel the Service before the end of your current paid up month, your cancellation will take effect immediately and you will not be charged again.</li>
                  <li>All of your Content will be immediately deleted from the Service upon cancellation. This information cannot be recovered once your account is cancelled.</li>
                </ol>
                <h2>Modifications to the Service and Prices</h2>
                <ol>
                  <li>Nimbly reserves the right at any time and from time to time to modify, temporarily or permanently, the Service (or any part thereof) with or without notice.</li>
                  <li>Prices of all Services, including but not limited to monthly subscription plan fees to the Service, are subject to change upon 30 days’ notice from us. Such notice may be provided at any time by posting the changes to the Nimbly Site (co) or the Service itself.</li>
                  <li>When using PayPal as your payment service, you are expected to comply with the PayPal Acceptable Use Policy.</li>
                </ol>
                <h2>Copyright and Content Ownership</h2>
                <ol>
                  <li>All content posted on the Service must comply with Singapore copyright law.</li>
                  <li>Nimbly does not pre-screen Content, but Nimbly and its designee have the right (but not the obligation) in their sole discretion to refuse or remove any Content that is available via the Service.</li>
                </ol>
                <h2>General Conditions</h2>
                <ol>
                  <li>Your use of the Service is at your sole risk. The service is provided on an “as is” and “as available” basis.</li>
                  <li>You understand that Nimbly uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.</li>
                  <li>You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service, Nimbly, or any other Nimbly service.</li>
                  <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by Nimbly.</li>
                  <li>We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.</li>
                </ol>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer open=""/>
    </div>)
  }
}
export default privacyPage;
