import React, {Component} from 'react';
import nl2br from 'react-newline-to-break';
import {
  Container,
  Row,
  Col,
  Button,
  Image
} from 'react-bootstrap';
import {Helmet} from 'react-helmet'

// Utilities
import Slider from "react-slick";

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var axios = require('axios');

function NextArrow(props) {
  const {className, onClick} = props;
  return (<div className={className} onClick={onClick}>
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" className="ml-1 arrow">
    <path d="M8.093 6.02699L2.122 12L-9.2712e-08 9.879L3.851 6.028L0.000999021 2.17799L2.122 0.0570067L8.093 6.02699Z" fill="#fff"/>
    </svg>
  </div>);
}

function PrevArrow(props) {
  const {className, onClick} = props;
  return (<div className={className} onClick={onClick}>
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" className="mr-1 arrow">
    <path d="M8.093 6.02699L2.122 12L-9.2712e-08 9.879L3.851 6.028L0.000999021 2.17799L2.122 0.0570067L8.093 6.02699Z" fill="#fff"/>
    </svg>
  </div>);
}

class careersPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      testimonialsList: [], // testimonials
      vacansList: [], // Vacans
      visible: 4,
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);

    this.loadMore = this.loadMore.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 4};
    });
  }

  componentDidMount() {
    // Testimonials JSON
    axios.get('../Data/career_testimonials.json').then(response => {
      this.setState({testimonialsList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
    // Vacancies JSON
    axios.get('../Data/vacancies.json').then(response => {
      this.setState({vacansList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  render() {

    /*
    testimonials Slider
    */
    const testimonialsSlider = {
      autoplay: false,
      dots: true,
      infinite: true,
      arrows: true,
      centerMode: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-testimonials',
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>
    };

    // testimonials List
    const testimonialsList = this.state.testimonialsList;
    let testimonialsListBlock = '';

    if (testimonialsList.length > 0) {
      testimonialsListBlock = testimonialsList.map(function(obj,i) {
        return (<div key={i} className="text-sm-left text-center">
          <Row>
          <Col lg={5} className="pt-lg-4 pb-lg-4 mb-3">
            <div className="experiences--container pl-lg-5 pr-4 pt-lg-4 pb-lg-4 mb-lg-4">
              <p className="quote d-inline-block mb-1" style={{
                  width: '35px'
                }}></p>
              <p className="">
                {obj.description}
              </p>
              <footer className="d-flex align-items-center flex-column flex-md-row text-center text-md-left">
                <figure className="d-inline-block mr-0 mr-md-4">
                  <Image className="experiences--picture" src={obj.picture} roundedCircle="roundedCircle"/>
                </figure>
                <div className="d-inline-block">
                  <span className="text-dark">{obj.name}</span>
                  <p>
                    <small>{nl2br(obj.position)}</small>
                  </p>
                </div>
              </footer>
            </div>
          </Col>
          <Col lg={7} className=" d-none d-lg-block">
            <figure className="d-inline-block mr-4 experiences--background image">
              <Image className="object-fit_cover image" height="100%" src={obj.background} style={{'maxHeight': '450px'}} />
            </figure>
          </Col>
        </Row>
      </div>)
      });
    }

    return (<div className="main careers">
      <Helmet>
        <title>Nimbly - Careers</title>
        <meta name="description" content=""/>
      </Helmet>

      <Header className="header"/>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 mb-5 career--header">
        <Container className="pt-5 mt-5">
          <Row>
            <Col>
              <h1 className="h5 mb-5 underline-left underline-white text-white">Careers</h1>
              <h2 className="h1 text-white">Life is short.<br/> Work somewhere awesome.</h2>
            <p className=" mb-5 pt-lg-4 h3 text-white" style={{'fontWeight':'500', 'maxWidth':'500px'}}>
  We’re looking for someone who believes it’s just a matter of figuring it out. If that sounds up your alley, the best jobs are staring at you!
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 mb-5">
        <Container className="">
          <Row>
            <Col sm={12}>
              <h2 className="underline-left underline-yellow mb-5">Our Values</h2>
            </Col>
            <Col md={6} className="">
              <ul className="values">
                <li>Be persistent and own your success</li>
                <li>Propel diversity as a source of innovation</li>
                <li>Empower, not control</li>
              </ul>
            </Col>
            <Col md={6} className="">
              <ul className="values">
                <li>Deliver Impact</li>
                <li>Delight the customer at every turn</li>
                <li>Serve your Community</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-lg-5 pb-5 mt-lg-5 mb-2 bg-gray">
        <Container className="mt-lg-4">
          <div className="pt-5"></div>
          <Slider {...testimonialsSlider} className="pl-5 pr-5">
                {testimonialsListBlock}
          </Slider>
          <div className="pt-5 pb-lg-4"></div>
        </Container>
      </section>

      <section className=" pb-4 pb-lg-5 mb-5 mt-5">
        <Container className="">
          <Row>
            <Col sm={12} className="mb-5">
              <h2 className="h5 text-center underline-center underline-yellow mb-5">Job vacancies</h2>
            </Col>

                {this.state.vacansList.slice(0, this.state.visible).map((item, index) => {
                  return (
                    <Col md={6} className="mb-5" key={index}>
                      <a href={item.link} target="_blank" rel="noopener noreferrer" className="nimbled justify-content-center justify-content-md-between btn btn-outline-stripped">
                        {item.title}
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.387" height="20.221" viewBox="0 0 14.387 20.221" className="arrow d-none d-md-block">
                          <g id="Grupo_68" data-name="Grupo 68" transform="translate(-5.674 -0.809)">
                            <path id="Trazado_36" data-name="Trazado 36" d="M9.242,1.515l9.4,9.4-9.4,9.4" fill="none" stroke="#6642c9" strokeMiterlimit="10" strokeWidth="2"/>
                          </g>
                        </svg>
                      </a>
                    </Col>
                  );
                })}
              <Col sm={12} className="text-center mt-3 mt-lg-5">
                  {this.state.visible < this.state.vacansList.length &&
                          <Button onClick={this.loadMore} variant="purple" className="nimbled">
                          See more
                          </Button>
                  }
              </Col>
          </Row>
        </Container>
      </section>

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default careersPage;
