import React, {Component} from 'react';
import {
  Image
} from 'react-bootstrap';
import SocialMediaButtons from '../Utilities/Sharing';

class BlogArticle extends Component {
  constructor() {
    super();
    this.state = {
      openShare: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openShare: true })
  }

  stripHtml(html){
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  render() {
    var desc = this.stripHtml(this.props.body);
    console.log(this.props.image);
    return (
      <div className={this.props.className}>
        {this.props.image !== '' && this.props.image !== 'undefined' && this.props.image !== null &&
          <figure className="image">
            <Image src={this.props.image} alt={this.props.slug} className="object-fit_cover"/>
          </figure>
        }

        {this.props.cat !== '' && this.props.cat !== 'undefined' && this.props.cat !== null &&
        <p className="bold text-center text-lg-left underline-center underline-lg-left underline-yellow mb-4">
          {this.props.cat}
        </p>
        }

        <h5 className="text-green text-center text-lg-left">
          {this.props.title}
        </h5>

        {this.props.date !== '' && this.props.date !== 'undefined' && this.props.date !== null &&
          <p className="text-midgray text-center text-lg-left">
            {this.props.date}
          </p>
        }

        <p className="">
          {desc !== '' && desc !== 'undefined' && desc !== null &&
            desc.substring(0,155) + '...'
          }
        </p>

        <div className="d-sm-flex justify-content-sm-between">
          <a href={this.props.slug} className="btn btn-purple nimbled pr-4 pl-4 d-block d-sm-inline-flex text-center text-md-left">Read More</a>
            <SocialMediaButtons url={window.location.origin.toString()+this.props.slug} text={this.props.title}/>
        </div>
      </div>
      )
  }
}
export default BlogArticle;
