import React, {Component} from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {Helmet} from 'react-helmet'

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

// Import Utilities
import BlogArticle from '../Includes/_Blog-article';
import ThinkBigger from '../Includes/_Think-bigger';
import SocialMediaButtons from '../Utilities/Sharing';

// Import Utils
// import { getPostId, getPostSlug } from '../Utilities/Getpost';

var axios = require('axios');

class blogPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      articlesList: [], // Articles
      openVideo: false,
      loading: true
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  componentDidMount() {
    const { post } = this.props.match.params;

    console.log(post);

    // Article JSON
    axios.get('../Data/blog-articles.json').then(response => {
      var obj = response.data.filter((obj) => {
        return obj.slug === post
      }).map(function(obj,i) {
        return obj
      });
      console.log(obj);
      this.setState({
        body: obj[0].body,
        title: obj[0].title,
        cat: obj[0].cat,
        loading: false
      });
    }).catch(function(error) {
      console.log(error);
    });

    // Related Articles JSON
    axios.get('../Data/blog-articles.json').then(response => {
      this.setState({
        articlesList: response.data
      });
    }).catch(function(error) {
      console.log(error);
    });

    // getPostSlug(post)
    //   .then((res) => {
    //     const { title, body, cat } = res.data[0];
    //     this.setState({
    //       body,
    //       title,
    //       cat,
    //       loading: false
    //     });
    //   })
    //   .catch((err) => console.log(err))
  }

  render() {
    /*
    Articles
    */
    const articlesList = this.state.articlesList;
    let articlesListBlock = '';
    var link;

    if (articlesList.length > 0) {
      articlesListBlock = articlesList.map((obj, i) => {
      link = '/blog/'+obj.slug;
      return (
        <BlogArticle className="slider-article mb-lg-3 pb-5 col-lg-6 col-xl-4" key={i} body={obj.body} image={obj.image} title={obj.title} cat={obj.cat} date={obj.date} slug={link}/>)
      })
    }

    return (<div className="main blog">
      <Helmet>
        <title>Nimbly - Blog</title>
        <meta name="description" content="React JS Home"/>
      </Helmet>

      <Header className="header"/>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 ">
        <Container className="pt-5  blog--header2">
          <Row>
            <Col xl={5} className="pl-xl-5">

            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-4 pb-lg-5 mt-lg-5">
        <Container>
          <Row>
            <Col lg={9} xl={8} className="offset-lg-1 offset-xl-2">
              <h2 className="h5 mb-5 mt-4 mt-lg-0 text-center text-lg-left underline-center underline-lg-left underline-yellow">
                {this.state.cat}
              </h2>
              <h1 className="mb-5 text-green text-center text-lg-left">{this.state.title}</h1>
              <p>{this.state.body}</p>
              <SocialMediaButtons url={window.location.origin.toString()+this.state.slug} text={this.state.title}/>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-3 pt-lg-3 mt-lg-3 mb-lg-5">
        <Container className="pt-lg-5">
          <Row className="products-features">
            <Col sm={12}>
              <h2 className="h5 mb-5 text-center text-lg-left underline-center underline-lg-left underline-yellow text-dark d-block">Related Articles</h2>
            </Col>
            {articlesListBlock}
          </Row>
        </Container>
      </section>

      <ThinkBigger className="pt-5 pb-4 pt-lg-5 pb-lg-5 think_bigger--bg" />
      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default blogPage;
