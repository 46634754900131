import React, {Component} from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import ThinkBigger from '../Includes/_Think-bigger';
import BlogArticle from '../Includes/_Blog-article';

var axios = require('axios');

class blogPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      articlesList: [], // Articles
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  componentDidMount() {
    // Articles JSON
    axios.get('../Data/blog-articles.json').then(response => {
      this.setState({articlesList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  render() {
    /*
    Articles
    */
    const articlesList = this.state.articlesList;
    let articlesListBlock = '';
    var link;

    if (articlesList.length > 0) {
      articlesListBlock = articlesList.map((obj, i) => {
      link = '/blog/'+obj.slug;
      return (
        <BlogArticle className="slider-article mb-lg-3 pb-5 col-lg-6 col-xl-4" key={i} image={obj.image} title={obj.title} cat={obj.cat} date={obj.date} body={obj.body} slug={link}/>)
      })
    }

    return (<div className="main blog">
      <Helmet>
        <title>Nimbly - Blog</title>
        <meta name="description" content="React JS Home"/>
      </Helmet>

      <Header className="header"/>

    <section className="pb-4 pb-lg-5 mt-lg-5">
        <Container className="pt-5 blog--header">
          <Row>
            <Col xl={6} className="offset-xl-6 pl-lg-5">
              <div className="blog-box mb-4">
                <div className="">
                  <h1 className="h5 mb-5 mt-lg-0 text-center text-lg-left underline-center underline-lg-left underline-black">Blog</h1>
                  <h2 className="h1">Streamline your business by focusing on this 6 key areas</h2>
                  <p className="infobox-desc">
    Nowadays, digital transformation is necessary. Just like a living organism adapts to changing environmental conditions, digital transformation allows your business to quickly respond to market dynamics. Here are 6 tips that will make your digital transformation process successful ...
                  </p>
                    <NavLink to='' className="text-left btn btn-purple nimbled pr-4 pl-4 d-block d-md-inline-flex text-center text-md-left">Read More</NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-4 pb-5 mt-lg-5 mb-lg-5">
        <Container>
          <Row>
            <Col xl={6}>
              <div className="infobox-container">
                <figure className="infobox-image">
                  <img className="object-fit_cover" src="/Assets/Images/about_2.jpg" width="100%" height="100%" alt="bg1"/>
                </figure>

                <BlogArticle className="infobox-content" image="" title="Article dolor sit amet, consetetur sadipscing elitr" cat="" date="" body="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." slug="" />
              </div>
            </Col>
            <Col xl={6}>
              <div className="infobox-container">
                <figure className="infobox-image">
                  <img className="object-fit_cover" src="/Assets/Images/about_2.jpg" width="100%" height="100%" alt="bg1"/>
                </figure>
                <BlogArticle className="infobox-content" image="" title="Article dolor sit amet, consetetur sadipscing elitr" cat="" date="" body="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." slug="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ThinkBigger className="pt-5 pb-4 pt-lg-5 pb-lg-5 think_bigger--bg" />

    <section className="pt-5 mt-5 pt-lg-3 mt-lg-3 mb-lg-5">
        <Container className="mt-lg-5 pt-lg-5">
          <Row className="products-features">
            {articlesListBlock}
          </Row>
        </Container>
      </section>

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default blogPage;
