import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { Link } from "react-scroll";
import WindowSizeListener from 'react-window-size-listener';
import {Navbar, Nav, Collapse, Container, Accordion, Card} from 'react-bootstrap';
import CheeseburgerMenu from '../Utilities/Cheesemenu/index'

// Assets
import phoneImg from '../../Assets/Images/icon-phone.svg';

class NavBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.initialState = {
      screenWidth: 0,
      screenHeight: 0,
      link1: false,
      link2: false,
      link2Submenu: false,
      link3: false,
      link4: false,
      link5: false,
      menuOpen: false
    };
    this.state = this.initialState;
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    this.items = []
    for (let i=1; i<=100; i++) {
      this.items.push(i)
    }
  }

  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  updateLink(link) {
    if (this.state.screenWidth < 992) {
      this.setState(this.initialState);
      this.setState({[link]: true});
    }
  }

  sticky(header, sticky) {
    if (window.pageYOffset > sticky) {
      header.classList.add("header-sticky");
    } else {
      header.classList.remove("header-sticky");
    }
  }

  componentDidMount() {
    var header = document.getElementById("header");
    var sticky = header.offsetTop + header.style.marginTop;
    window.addEventListener('scroll', () => this.sticky(header, sticky));
  }

  render() {
    const {link1, link2, link3, link4, link5} = this.state;
    // window.onscroll = function() {sticky(header, sticky)};
    return (<header id="header" className={this.props.className}>
      <Container>
      <NavLink to="/" className="text-white d-lg-none logo">
        <svg width="48" height="42" viewBox="0 0 48 42" fill="none" className="mt-4">
        <path d="M2.18547 10.9756H0V41.9994H2.18547V10.9756Z" fill="#3cd070"/>
        <path d="M7.01067 11.5223H4.8252V42H7.01067V11.5223Z" fill="#3cd070"/>
        <path d="M11.8359 11.9236H9.65039V42H11.8359V11.9236Z" fill="#3cd070"/>
        <path d="M30.4458 41.9993H28.2603V38.4035C28.049 33.7283 26.8195 29.1551 24.6572 25.0021C19.974 16.2486 11.6782 11.8101 0.00244141 11.8101V9.62939C15.6759 9.62939 23.1328 17.4719 26.6278 24.0503C28.9499 28.4926 30.2548 33.3938 30.4483 38.4004L30.4458 41.9993Z" fill="#3cd070"/>
        <path d="M35.271 41.9968H33.0855V38.401C32.858 32.8907 31.434 27.4954 28.9119 22.5881C23.4407 12.2421 13.7154 6.99536 0.00244141 6.99536V4.81592C18.2189 4.81592 26.8521 13.9701 30.8869 21.6495C33.5699 26.8438 35.068 32.566 35.2735 38.406L35.271 41.9968Z" fill="#3cd070"/>
        <path d="M40.0963 41.9994H37.9108V38.4061C37.6347 32.058 35.9932 25.8434 33.0975 20.1843C30.5203 15.1467 26.6539 10.8787 21.8905 7.813C16.014 4.0761 8.64883 2.18069 0.00244141 2.18069V0C9.0956 0 16.8704 2.01955 23.1108 6.00224C28.1987 9.285 32.3251 13.8532 35.0706 19.2425C38.13 25.1856 39.8459 31.7258 40.0975 38.4023L40.0963 41.9994Z" fill="#3cd070"/>
        <path d="M40.4671 9.7373C28.4828 20.9844 28.3138 37.6738 28.3125 38.381V41.9768H30.498V38.3829C30.498 38.2205 30.6588 22.0384 41.857 11.4271L40.4671 9.7373Z" fill="#3cd070"/>
        <path d="M47.9993 19.1867L46.6301 17.4688L46.5371 17.5615C38.0666 25.9784 37.941 37.8976 37.9397 38.4035V41.9994H40.1252V38.4073C40.1252 38.2932 40.2634 26.9496 47.9999 19.1861" fill="#3cd070"/>
        <path d="M43.5309 13.5814C33.2797 23.4196 33.1131 37.7991 33.1138 38.4035V41.9994H35.2992V38.4029C35.2992 38.2643 35.401 24.4949 44.8989 15.2943L43.5309 13.5814Z" fill="#3cd070"/>
        </svg>
      </NavLink>
      <Navbar className="container" expand="lg">
        <WindowSizeListener onResize={windowSize => {
            this.setState({screenWidth: window.innerWidth, screenHeight: window.innerHeight});
          }}/>
        <NavLink to="/" className="text-white d-none d-lg-inline-block logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="187.469" height="51.018" viewBox="0 0 187.469 51.018">
            <g id="Grupo_166" data-name="Grupo 166" transform="translate(-28.796 -28.346)">
              <g id="Grupo_162" data-name="Grupo 162" transform="translate(28.796 28.346)">
                <g id="Grupo_155" data-name="Grupo 155" transform="translate(0 11.89)">
                  <g id="Grupo_152" data-name="Grupo 152">
                    <rect id="Rectángulo_148" data-name="Rectángulo 148" width="2.362" height="33.609" fill="#3cd070"/>
                  </g>
                  <g id="Grupo_153" data-name="Grupo 153" transform="translate(5.216 0.592)">
                    <rect id="Rectángulo_149" data-name="Rectángulo 149" width="2.362" height="33.017" fill="#3cd070"/>
                  </g>
                  <g id="Grupo_154" data-name="Grupo 154" transform="translate(10.431 1.027)">
                    <rect id="Rectángulo_150" data-name="Rectángulo 150" width="2.362" height="32.582" fill="#3cd070"/>
                  </g>
                </g>
                <g id="Grupo_156" data-name="Grupo 156" transform="translate(0 10.432)">
                  <path id="Trazado_125" data-name="Trazado 125" d="M61.706,108.916H59.344v-3.9A34.9,34.9,0,0,0,55.449,90.5C50.387,81.02,41.419,76.211,28.8,76.211V73.849c16.942,0,25,8.5,28.78,15.623a36.67,36.67,0,0,1,4.13,15.546Z" transform="translate(-28.796 -73.849)" fill="#3cd070"/>
                </g>
                <g id="Grupo_157" data-name="Grupo 157" transform="translate(0 5.216)">
                  <path id="Trazado_126" data-name="Trazado 126" d="M66.922,91.381H64.56v-3.9a41.263,41.263,0,0,0-4.511-17.13C54.136,59.144,43.621,53.46,28.8,53.46V51.1c19.691,0,29.023,9.917,33.384,18.236a42.909,42.909,0,0,1,4.742,18.152Z" transform="translate(-28.796 -51.098)" fill="#3cd070"/>
                </g>
                <g id="Grupo_158" data-name="Grupo 158" transform="translate(0)">
                  <path id="Trazado_127" data-name="Trazado 127" d="M72.138,73.845H69.775v-3.9a47.986,47.986,0,0,0-5.2-19.74,34.748,34.748,0,0,0-12.114-13.4c-6.353-4.049-14.314-6.1-23.663-6.1V28.346c9.829,0,18.233,2.188,24.979,6.5a37.1,37.1,0,0,1,12.93,14.343,49.5,49.5,0,0,1,5.432,20.756Z" transform="translate(-28.796 -28.346)" fill="#3cd070"/>
                </g>
                <g id="Grupo_159" data-name="Grupo 159" transform="translate(30.604 10.549)">
                  <path id="Trazado_128" data-name="Trazado 128" d="M175.431,74.36c-12.954,12.184-13.137,30.264-13.139,31.03v3.9h2.362v-3.893c0-.176.174-17.706,12.278-29.2Z" transform="translate(-162.292 -74.36)" fill="#3cd070"/>
                </g>
                <g id="Grupo_160" data-name="Grupo 160" transform="translate(41.01 18.924)">
                  <path id="Trazado_129" data-name="Trazado 129" d="M218.558,112.754l-1.48-1.861-.1.1c-9.156,9.118-9.292,22.03-9.293,22.579v3.9h2.362v-3.891c0-.124.149-12.412,8.512-20.823" transform="translate(-207.684 -110.893)" fill="#3cd070"/>
                </g>
                <g id="Grupo_161" data-name="Grupo 161" transform="translate(35.794 14.713)">
                  <path id="Trazado_130" data-name="Trazado 130" d="M196.193,92.525c-11.081,10.658-11.261,26.236-11.261,26.89v3.9h2.362v-3.9c0-.15.11-15.067,10.377-25.034Z" transform="translate(-184.932 -92.525)" fill="#3cd070"/>
                </g>
              </g>
              <g id="Grupo_165" data-name="Grupo 165" transform="translate(87.178 37.222)">
                <g id="Grupo_163" data-name="Grupo 163" transform="translate(0 20.827)">
                  <path id="Trazado_131" data-name="Trazado 131" d="M283.465,165.478h10.324v2.279h-3.751v11.8h-2.823v-11.8h-3.751Z" transform="translate(-283.465 -163.744)" fill="#3cd070"/>
                  <path id="Trazado_132" data-name="Trazado 132" d="M331.385,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.6,6.6,0,0,1-.081,1.089h-8.167a2.614,2.614,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351h3.045A5.192,5.192,0,0,1,331.385,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,328.662,182.071Z" transform="translate(-316.079 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_133" data-name="Trazado 133" d="M384.025,177.353a4.984,4.984,0,0,1,5.263,3.952h-3.045a2.195,2.195,0,0,0-2.238-1.553c-1.573,0-2.622,1.19-2.622,3.368s1.049,3.347,2.622,3.347a2.131,2.131,0,0,0,2.238-1.533h3.045a5.1,5.1,0,0,1-5.263,3.953c-3.226,0-5.525-2.259-5.525-5.767S380.8,177.353,384.025,177.353Z" transform="translate(-356.713 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_134" data-name="Trazado 134" d="M432.566,161.783h2.823v5.142a4.292,4.292,0,0,1,3.468-1.553c2.521,0,4.356,1.694,4.356,4.779V176.7H440.39v-6.17c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.52.968-2.52,2.763v6.17h-2.823Z" transform="translate(-398.385 -160.896)" fill="#3cd070"/>
                  <path id="Trazado_135" data-name="Trazado 135" d="M496.594,182.6c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.521.968-2.521,2.763v6.17H488.77V177.6h2.823v1.391a4.173,4.173,0,0,1,3.368-1.553c2.6,0,4.456,1.694,4.456,4.779v6.553h-2.823Z" transform="translate(-441.704 -172.963)" fill="#3cd070"/>
                  <path id="Trazado_136" data-name="Trazado 136" d="M547.678,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,547.678,188.887Zm0-2.461a2.974,2.974,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C544.874,185.338,546.165,186.427,547.678,186.427Z" transform="translate(-482.739 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_137" data-name="Trazado 137" d="M599.445,161.783h2.823V176.7h-2.823Z" transform="translate(-527.007 -160.896)" fill="#3cd070"/>
                  <path id="Trazado_138" data-name="Trazado 138" d="M624.586,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,624.586,188.887Zm0-2.461a2.973,2.973,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C621.783,185.338,623.074,186.427,624.586,186.427Z" transform="translate(-542.017 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_139" data-name="Trazado 139" d="M678.45,177.353a4.37,4.37,0,0,1,3.69,1.774v-1.593h2.843v11.252c0,3.025-1.855,5.424-5.525,5.424-3.146,0-5.344-1.573-5.626-4.134h2.8a2.627,2.627,0,0,0,2.743,1.674c1.552,0,2.762-.887,2.762-2.964v-1.734a4.406,4.406,0,0,1-3.69,1.835c-2.843,0-5.1-2.319-5.1-5.808S675.606,177.353,678.45,177.353Zm.726,2.48a2.98,2.98,0,0,0-2.944,3.247,3.053,3.053,0,0,0,2.944,3.327,3.3,3.3,0,0,0,0-6.574Z" transform="translate(-583.967 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_140" data-name="Trazado 140" d="M732.9,159.567a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,732.9,159.567Zm.3,2.984h2.823v11.171H733.2Z" transform="translate(-629.869 -157.913)" fill="#3cd070"/>
                  <path id="Trazado_141" data-name="Trazado 141" d="M758.977,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.591,6.591,0,0,1-.08,1.089h-8.167a2.613,2.613,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351H764.2A5.192,5.192,0,0,1,758.977,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,756.254,182.071Z" transform="translate(-645.645 -172.896)" fill="#3cd070"/>
                  <path id="Trazado_142" data-name="Trazado 142" d="M810.962,188.887c-2.8,0-4.759-1.633-4.88-3.711h2.843a1.854,1.854,0,0,0,2.017,1.492c1.109,0,1.714-.5,1.714-1.149,0-2-6.311-.726-6.311-4.82,0-1.855,1.654-3.347,4.416-3.347,2.7,0,4.336,1.472,4.5,3.69h-2.7a1.664,1.664,0,0,0-1.875-1.452c-1.048,0-1.593.423-1.593,1.069,0,2.036,6.19.766,6.271,4.859C815.358,187.435,813.684,188.887,810.962,188.887Z" transform="translate(-686.272 -172.896)" fill="#3cd070"/>
                </g>
                <g id="Grupo_164" data-name="Grupo 164">
                  <path id="Trazado_143" data-name="Trazado 143" d="M295.5,74.541v14.1H292.68l-6.392-9.659v9.659h-2.823v-14.1h2.823l6.392,9.679V74.541Z" transform="translate(-283.465 -72.827)" fill="#3cd070"/>
                  <path id="Trazado_144" data-name="Trazado 144" d="M345.359,68.719a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,345.359,68.719Zm.3,2.984h2.823V82.874h-2.823Z" transform="translate(-331.17 -67.065)" fill="#3cd070"/>
                  <path id="Trazado_145" data-name="Trazado 145" d="M384.621,91.754a2.5,2.5,0,1,0-4.981,0v6.17h-2.823v-6.17a2.508,2.508,0,1,0-5,0v6.17h-2.823V86.754h2.823V88.1a4.067,4.067,0,0,1,3.287-1.512,4.275,4.275,0,0,1,3.932,2.138,4.423,4.423,0,0,1,3.831-2.138c2.682,0,4.577,1.694,4.577,4.779v6.553h-2.823Z" transform="translate(-349.386 -82.115)" fill="#3cd070"/>
                  <path id="Trazado_146" data-name="Trazado 146" d="M465.846,74.5c2.864,0,5.1,2.238,5.1,5.726s-2.238,5.808-5.1,5.808a4.4,4.4,0,0,1-3.69-1.774v1.593h-2.823V70.935h2.823v5.384A4.352,4.352,0,0,1,465.846,74.5Zm-.746,2.48a3.307,3.307,0,0,0,0,6.574,3.056,3.056,0,0,0,2.964-3.327A2.983,2.983,0,0,0,465.1,76.984Z" transform="translate(-419.015 -70.048)" fill="#3cd070"/>
                  <path id="Trazado_147" data-name="Trazado 147" d="M517.412,70.935h2.823V85.857h-2.823Z" transform="translate(-463.78 -70.048)" fill="#3cd070"/>
                  <path id="Trazado_148" data-name="Trazado 148" d="M543.132,87.3h3l-6.917,16.454h-3l2.419-5.565L534.158,87.3h3.166l2.884,7.8Z" transform="translate(-476.687 -82.659)" fill="#3cd070"/>
                </g>
              </g>
            </g>
          </svg>
        </NavLink>
        <Navbar id="basic-navbar-nav">
          <Nav className="d-flex justify-content-between">
            <NavLink className="nav-link d-flex d-lg-none align-items-center" exact activeClassName="active" to="/" onClick={() => this.updateLink('link1')} aria-controls="link1" aria-expanded={link1}>
              <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.38795 7.05573H0V26.9996H1.38795V7.05573Z" fill="#3BD070"/>
              <path d="M4.45241 7.4072H3.06445V27H4.45241V7.4072Z" fill="#3BD070"/>
              <path d="M7.51686 7.66516H6.12891V27H7.51686V7.66516Z" fill="#3BD070"/>
              <path d="M19.3354 26.9996H17.9475V24.688C17.8133 21.6825 17.0325 18.7426 15.6592 16.0728C12.685 10.4455 7.41651 7.5922 0.00146484 7.5922V6.19033C9.95537 6.19033 14.6911 11.2319 16.9107 15.4609C18.3854 18.3167 19.2142 21.4675 19.337 24.686L19.3354 26.9996Z" fill="#3BD070"/>
              <path d="M22.3999 26.998H21.0119V24.6864C20.8674 21.144 19.963 17.6757 18.3613 14.521C14.8866 7.86992 8.71029 4.49703 0.00146484 4.49703V3.09596C11.5704 3.09596 17.0532 8.98077 19.6156 13.9176C21.3195 17.2567 22.2709 20.9353 22.4015 24.6896L22.3999 26.998Z" fill="#3BD070"/>
              <path d="M25.4643 26.9996H24.0763V24.6896C23.901 20.6087 22.8585 16.6136 21.0195 12.9756C19.3827 9.73719 16.9273 6.99347 13.9021 5.02265C10.1701 2.62035 5.49262 1.40187 0.00146484 1.40187V0C5.77635 0 10.714 1.29829 14.6771 3.85858C17.9084 5.96893 20.5289 8.9056 22.2726 12.3702C24.2155 16.1907 25.3053 20.3951 25.4651 24.6872L25.4643 26.9996Z" fill="#3BD070"/>
              <path d="M25.6998 6.25968C18.0889 13.4899 17.9815 24.2188 17.9807 24.6735V26.9851H19.3687V24.6747C19.3687 24.5703 19.4708 14.1675 26.5826 7.34595L25.6998 6.25968Z" fill="#3BD070"/>
              <path d="M30.4836 12.3343L29.6141 11.2299L29.555 11.2896C24.1756 16.7004 24.0958 24.3627 24.095 24.688V26.9996H25.4829V24.6904C25.4829 24.6171 25.5707 17.3247 30.484 12.3339" fill="#3BD070"/>
              <path d="M27.6457 8.73089C21.1354 15.0555 21.0296 24.2994 21.03 24.688V26.9996H22.418V24.6876C22.418 24.5985 22.4826 15.7467 28.5145 9.83208L27.6457 8.73089Z" fill="#3BD070"/>
              </svg>

              <Collapse in={this.state.link1} dimension="width">
                <span id="link1">Home</span>
              </Collapse>
            </NavLink>
            <NavLink className="nav-link d-none d-lg-flex align-items-center" activeClassName="active" to="/features" onClick={() => this.updateLink('link5')} aria-controls="link5" aria-expanded={link5}>
              <Collapse in={this.state.link5} dimension="width">
                <span id="link5">Features</span>
              </Collapse>
            </NavLink>
            <NavLink className="nav-link d-flex align-items-center d-lg-none" activeClassName="active" to="/features" onClick={() => this.updateLink('link2')} aria-controls="link2" aria-expanded={link2}>
            <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9088 6.55521L12.1131 24.3509L1.31935 13.5675L0 14.8869L12.1131 27L31.2386 7.87457L29.9088 6.55521ZM28.1531 4.61254L26.8338 3.29319L12.1131 18.0035L4.70604 10.5964L3.38669 11.9157L12.1131 20.6422L28.1531 4.61254ZM25.3067 1.31935L23.9873 0L12.1131 11.8742L7.92651 7.68757L6.60716 9.01731L12.1131 14.5233L25.3067 1.31935Z" fill="#3BD070"/>
            </svg>
              <Collapse in={this.state.link2} dimension="width">
                <span id="link2">Features</span>
              </Collapse>
            </NavLink>
            <div className="nav-link align-items-center d-none d-lg-flex subMenu">
              <span style={{'cursor':'pointer'}}>
                Industries
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" className="ml-2 industries-icon" style={{'display' : 'inline-block','minWidth' : '10px'}}>
                <path d="M5.97301 8.093L0 2.122L2.121 0L5.972 3.851L9.82201 0.000999451L11.943 2.122L5.97301 8.093Z" fill="#3cd070"/>
                </svg>
              </span>
              <div className="subMenu--container" id="subMenu">
                <ul>
                  <li><NavLink to="/industry/retail" activeClassName="active"><img src="/Assets/Images/Icons/[Mobile] Retail icon.svg" alt="Retail" className="mr-3 d-inline-block" />Retail</NavLink></li>
                  <li><NavLink to="/industry/food-&-beverages" activeClassName="active"><img src="/Assets/Images/Icons/[Mobile] Food and beverages icon.svg" alt="Retail" className="mr-3 d-inline-block" />Food & Beverage</NavLink></li>
                  <li><NavLink to="/industry/fmcg" activeClassName="active"><img src="/Assets/Images/Icons/[Mobile] FMCG icon.svg" alt="Retail" className="mr-3 d-inline-block" />FMCG</NavLink></li>
                  <li><NavLink to="/industry/agriculture" activeClassName="active"><img src="/Assets/Images/Icons/[Mobile] Agriculture icon.svg" alt="Retail" className="mr-3 d-inline-block" />Agriculture</NavLink></li>
                  <li><NavLink to="/industry/facilities-management" activeClassName="active"><img src="/Assets/Images/Icons/[Mobile] Facilities icon.svg" alt="Retail" className="mr-3 d-inline-block" />Facility Management</NavLink></li>
                </ul>
              </div>
            </div>
            <NavLink className="nav-link d-flex align-items-center" activeClassName="active" to="/blog" onClick={() => this.updateLink('link5')} aria-controls="link5" aria-expanded={link5}>
              <svg width="29" height="27" viewBox="0 0 29 27" fill="none">
              <path d="M0 27H1.90365V14.6686H0V27ZM9.66628 27H11.5699V14.6686H9.66628V27ZM4.83314 27H6.73678V14.6686H4.83314V27ZM9.9624 0C4.47356 0 0 4.47356 0 9.9624H1.90365C1.90365 5.52056 5.52057 1.90364 9.9624 1.90364V0ZM9.9624 4.50529C6.9483 4.50529 4.50529 6.9483 4.50529 9.9624H6.40893C6.40893 8.00588 8.00588 6.40893 9.9624 6.40893V4.50529ZM17.2597 27H19.1633V14.6686H17.2597V27ZM26.926 14.6686V26.9894H28.8296V14.6686H26.926ZM22.0928 27H23.9965V14.6686H22.0928V27ZM27.2221 0C21.7333 0 17.2597 4.47356 17.2597 9.9624H19.1633C19.1633 5.52056 22.7803 1.90364 27.2221 1.90364V0ZM27.2221 4.50529C24.208 4.50529 21.765 6.9483 21.765 9.9624H23.6686C23.6686 8.00588 25.2656 6.40893 27.2221 6.40893V4.50529Z" fill="#3BD070"/>
              </svg>
              <Collapse in={this.state.link5} dimension="width">
                <span id="link5">Blog</span>
              </Collapse>
            </NavLink>
            <NavLink className="nav-link d-none d-lg-flex align-items-center" activeClassName="active" to="/faq" onClick={() => this.updateLink('link5')} aria-controls="link5" aria-expanded={link5}>
              <Collapse in={this.state.link5} dimension="width">
                <span id="link5">FAQ</span>
              </Collapse>
            </NavLink>
            <Link to="demo" spy={true} smooth={true} offset={-70} duration= {500} className="nav-link d-none d-lg-flex align-items-center  ml-xl-5 pl-xl-3" onClick={() => this.updateLink('link3')} aria-controls="link3" aria-expanded={link3}>
              <Collapse in={this.state.link3} dimension="width" className="fill" style={{'cursor':'pointer'}}>
                <span id="link3">Request a demo</span>
              </Collapse>
            </Link>
            <NavLink className="nav-link d-none d-lg-flex align-items-center" activeClassName="active" to="/login" onClick={() => this.updateLink('link5')} aria-controls="link5" aria-expanded={link5}>
              <Collapse in={this.state.link5} dimension="width" className="outline">
                <span id="link5">Login
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" className="ml-2 login-icon" style={{'display' : 'inline-block','minWidth' : '10px'}}>
                <path d="M5.63904 0V1.5H10.844V13.93H5.63904V15.43H12.344V0H5.63904Z" fill="#3cd070"/>
                <path d="M5.501 4.044L4.44 5.104L6.3 6.965H0V8.465H6.3L4.44 10.325L5.501 11.386L9.172 7.715L5.501 4.044Z" fill="#3cd070"/>
                </svg>
                </span>
              </Collapse>
            </NavLink>
            <NavLink className="nav-link d-flex align-items-center d-lg-none" activeClassName="active" to="/about-us" onClick={() => this.updateLink('link4')} aria-controls="link4" aria-expanded={link4}>
              <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.95565 22.4879H23.2822V20.6165H8.95565V22.4879ZM12.5425 27H19.7057V25.1286H12.5425V27ZM4.22519 17.2376H28.0126V15.3662H4.22519V17.2376ZM1.49089 12.4032H30.7366V10.5318H1.49089V12.4032ZM13.8525 5.35426H0.731934V7.22565H13.8525V5.35426ZM18.3854 5.35426V7.22565H31.5059V5.35426H18.3854ZM10.3592 0H4.22519V1.87139H10.3592V0ZM28.0126 0H21.8786V1.87139H28.0126V0Z" fill="#3BD070"/>
              </svg>
              <Collapse in={this.state.link4} dimension="width">
                <span id="link4">About us</span>
              </Collapse>
            </NavLink>
            <Link className="nav-link d-flex align-items-center d-lg-none" to="/" onClick={this.openMenu.bind(this)} aria-controls="link5" aria-expanded={link5}>
              <svg width="28" height="21" viewBox="0 0 28 21" fill="none">
                <path d="M0.505859 0V1.95757H27.5059V0H0.505859ZM0.505859 7.11251H27.5059V5.15494H0.505859V7.11251ZM11.7475 20.25H27.5059V18.2924H11.7475V20.25ZM11.7475 14.8558H27.5059V12.8982H11.7475V14.8558Z" fill="#3BD070"/>
              </svg>
              <Collapse in={this.state.link5} dimension="width">
                <span id="link5">Industries</span>
              </Collapse>
            </Link>
          </Nav>
        </Navbar>
      </Navbar>
      </Container>
      <div className="callforaction d-lg-none">
        {/*<Link to="demo" spy={true} smooth={true} offset={-70} duration= {500}  variant="green" className="nimbled small btn btn-green">Demo Request</Link>*/}
        <a href="tel:+62 821 2306 9155" className="nimbled-rounded btn btn-darkgreen"><img src={phoneImg}  alt="call" /></a>
      </div>
      <CheeseburgerMenu isOpen={this.state.menuOpen} closeCallback={this.closeMenu.bind(this)} {...this.props.menuProps}>
        <div className="swipe-menu justify-content-between flex-column d-flex" style={{height : '100%'}}>
          <ul>
            <li className="mb-4 d-flex align-items-center">
              <NavLink to='/careers' className="d-flex align-items-center">
              <svg width="28" height="23" viewBox="0 0 28 23" fill="none" className="mr-3">
              <path d="M0 22.84H27.99V21.04H0V22.84ZM0 7V8.8H27.99V7H0ZM9.47 1.8H19.11V5.32H20.91V0H7.67V5.32H9.47V1.8ZM18.42 11.81H9.57V13.8H0V15.6H9.57V17.59H18.42V15.6H27.99V13.8H18.42V11.81ZM16.62 15.79H11.37V13.61H16.62V15.79Z" fill="#3BD070"/>
              </svg>
              Careers</NavLink>
            </li>
            <li className="mb-4 d-flex align-items-center">
              <NavLink to='/faq' className="d-flex align-items-center">
              <svg width="29" height="27" viewBox="0 0 29 27" fill="none" className="mr-3">
              <path fillRule="evenodd" clipRule="evenodd" d="M28.8339 0.59C28.8239 0.56 28.8139 0.54 28.7939 0.51C28.7539 0.44 28.7139 0.37 28.6639 0.31C28.6539 0.3 28.6464 0.29 28.6389 0.28C28.6314 0.27 28.6239 0.26 28.6139 0.25C28.5439 0.18 28.4639 0.12 28.3739 0.08H28.3639C28.3539 0.075 28.3464 0.0725 28.3389 0.07L28.3388 0.0699971C28.3313 0.0674981 28.3238 0.0649981 28.3139 0.06C28.2239 0.03 28.1439 0.01 28.0539 0.01C28.0373 0.01 28.0269 0.00696938 28.0175 0.00424492C28.0098 0.00202041 28.0028 0 27.9939 0H5.05385C4.85385 0 4.65385 0.07 4.49385 0.2L0.33385 3.57C0.0338504 3.81 -0.0761496 4.21 0.0538504 4.57C0.18385 4.93 0.52385 5.17 0.90385 5.17H23.1839V24.7H0.90385V26.5H24.0839C24.2439 26.5 24.4039 26.46 24.5539 26.37L28.4639 24C28.7339 23.84 28.8939 23.55 28.8939 23.23V0.9C28.8939 0.79 28.8739 0.69 28.8339 0.59ZM5.37385 1.8H25.5739L23.7539 3.37H3.44385L5.37385 1.8ZM27.0939 22.73L24.9839 24V4.68L27.0939 2.86V22.73ZM0.903809 7.96001H8.00381V9.76001H0.903809V7.96001ZM0.903809 14.04H8.00381V15.84H0.903809V14.04ZM8.00381 20.12H0.903809V21.92H8.00381V20.12ZM13.9639 20.12H16.0239V21.92H13.9639V20.12ZM14.994 7.19C17.564 7.19 19.654 9.28 19.654 11.85C19.654 14.12 18.034 16.01 15.894 16.43V18.31H14.094V14.71H14.994C16.574 14.71 17.854 13.43 17.854 11.85C17.854 10.27 16.574 8.99 14.994 8.99C13.414 8.99 12.134 10.27 12.134 11.85H10.334C10.334 9.28 12.424 7.19 14.994 7.19Z" fill="#3BD070"/>
              </svg>FAQ</NavLink>
            </li>
            <li className="mb-4">
              <Accordion>
                <Card className="m-0">
                  <Card.Header className="p-0">
                    <Accordion.Toggle eventKey="0" className="text-green btn" style={{'fontSize': 'inherit'}}>Industries
                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" className="ml-2 industries-icon" style={{'display' : 'inline-block','minWidth' : '10px'}}>
                    <path d="M5.97301 8.093L0 2.122L2.121 0L5.972 3.851L9.82201 0.000999451L11.943 2.122L5.97301 8.093Z" fill="#3cd070"/>
                    </svg>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="mb-4 pr-1">
                      <div className="submenu d-block mb-2">
                        <NavLink to="/industry/retail" className="d-block mb-lg-2 mb-4 text-gray"><img src="/Assets/Images/Icons/[Mobile] Retail icon-gray.svg" alt="Retail" className="mr-3" />Retail</NavLink>
                        <NavLink to="/industry/food-&-beverages" className="d-block  mb-lg-2 mb-4 text-gray"><img src="/Assets/Images/Icons/[Mobile] Food and beverages icon-gray.svg" alt="Retail" className="mr-3" />Food & Beverage</NavLink>
                        <NavLink to="/industry/fmcg" className="d-block mb-lg-2 mb-4 text-gray"><img src="/Assets/Images/Icons/[Mobile] FMCG icon-gray.svg" alt="Retail" className="mr-3" />FMCG</NavLink>
                        <NavLink to="/industry/agriculture" className="d-block mb-lg-2 mb-4 text-gray"><img src="/Assets/Images/Icons/[Mobile] Agriculture icon-gray.svg" alt="Retail" className="mr-3" />Agriculture</NavLink>
                        <NavLink to="/industry/facilities-management" className="d-block mb-lg-2 mb-4 text-gray"><img src="/Assets/Images/Icons/[Mobile] Facilities icon-gray.svg" alt="Retail" className="mr-3" />Facility Management</NavLink>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </li>
          </ul>
          <div className="ml-4 mr-4 mb-3 d-flex justify-content-between align-items-center">
          <svg width="27" height="21" viewBox="0 0 27 21" fill="none" onClick={() => {this.closeMenu()}}>
            <path d="M0 0V1.95757H27V0H0V0ZM0 7.11251H27V5.15494H0V7.11251ZM11.2417 20.25H27V18.2924H11.2417V20.25ZM11.2417 14.8558H27V12.8982H11.2417V14.8558Z" fill="#3BD070"/>
          </svg>
          <NavLink to="/login" className="d-inline-block btn btn-outline-green nimbled">Login</NavLink>
          </div>
        </div>
      </CheeseburgerMenu>
    </header>)
  }
}
export default NavBar;
