import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import {Helmet} from 'react-helmet'
import { Link } from "react-scroll";

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import ThinkBigger from '../Includes/_Think-bigger';

var axios = require('axios');

class featuresPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      productsList: [], // Products
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);

    this.loadMore = this.loadMore.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 5};
    });
  }

  componentDidMount() {
    // Products JSON
    axios.get('../Data/features.json').then(response => {
      this.setState({productsList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  render() {
    /*
    Products
    */
    const productsList = this.state.productsList;
    let productsListBlock = '';

    if (productsList.length > 0) {
      productsListBlock = productsList.map((obj, i) => {
        return (<Col lg={4} className="slider-feature no-shadow text-center" key={i}>
          <img src={obj.icon} alt={obj.title}/>
          <h2 className="h5 mb-3 text-center">
            {obj.title}
          </h2>
          <p className="text-center">
            {obj.description}
          </p>
        </Col>)
      })
    }

    return (<div className="main">
      <Helmet>
        <title>Nimbly - Features</title>
        <meta name="description" content=""/>
      </Helmet>

      <Header className="header"/>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 features--header">
        <Container className="pt-5 mt-5">
          <Row>
            <Col lg={6} className="pl-lg-5">
              <h1 className="h5 mb-5 underline-left underline-white text-white">Features</h1>
              <h2 className="h1 text-white">One platform, numerous opportunities</h2>
              <p className=" mb-5 pt-lg-4 h3 text-white" style={{'fontWeight':'500'}}>
              Nimbly enables clients to improve branch operations and customer service by ensuring SOP implementation and digitalizing the monitoring & follow up process in real-time.
              Gain breakthrough efficiency and operational excellence on-the-go with Nimbly.
              </p>
            </Col>
            <Col sm={12} className="pl-lg-5 text-center text-lg-left">
              <Button variant="outline-orange" className="nimbled p-2 pl-3 pr-3 mr-3" onClick={this.openModal}>
                How it works?
              </Button>
              <Link to="demo" spy={true} smooth={true} offset={-70} duration= {500} className="nimbled d-none d-lg-inline-block text-white btn btn-orange">
                Get started now</Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 pb-4 mb-5 mt-5">
        <Container>
          <h2 className="h5 text-center underline-center underline-yellow">The features that set us apart</h2>
          <Row className="products-features">
            {productsListBlock}
          </Row>
        </Container>
      </section>

      <ThinkBigger className="pt-5 pb-4 pt-lg-5 pb-lg-5 mt-5 think_bigger--bg" />

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default featuresPage;
