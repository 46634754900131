import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl
} from 'react-bootstrap';

// Utilities
import ResponseModal from '../Utilities/ResponseModal';

var axios = require('axios');

class ThinkBigger extends Component {
  constructor() {
    super();
    this.state = {
      validated: false,
      openResponse: false,
      response: '',
      form: {
        name: '',
        email: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    var _app = this;

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios({
        method: 'post',
        url: 'https://admin.hellonimbly.com/api/newsletter',
        data: this.state.form
      }).then(function (response) {
        _app.setState({response: response.data.message});
        _app.setState({openResponse: true});
        _app.setState({form: {
          name: '',
          email: ''
        }});
      });
    }
    this.setState({validated: true});
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      form : {
        ...this.state.form,
        [name]: value
      }
    });
  }

  render() {
    const {validated} = this.state;

    return (
      <section className={this.props.className}>
        <Container className="pt-lg-5 mt-5 mb-5">
          <Row>
            <Col lg={5} className="offset-lg-1 pr-lg-5">
              <h2 className="mb-4 pr-lg-5">Think bigger, be greater</h2>
              <p className="pr-lg-5 mb-5">Get access to our tips & secrets that help your business grow. Subscribe now.</p>
            </Col>
            <Col lg={5} className="">
              <Form noValidate="noValidate" validated={validated} onSubmit={e => this.handleSubmit(e)} className="row">
                <Form.Group className="col-12">
                  <FormControl placeholder="What's your name?" aria-label="Full Name" name="name" aria-describedby="basic-addon2" style={{
                      borderRadius: 10,
                      height: '50px'
                    }} required="required" onChange={this.handleChange} value={this.state.form.name}/>
                </Form.Group>
                <Form.Group className="col-12">
                  <FormControl type="email" name="email" placeholder="Insert your business email" aria-label="Email address" aria-describedby="basic-addon2" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" style={{
                      borderRadius: 10,
                      height: '50px'
                    }} required="required" onChange={this.handleChange} value={this.state.form.email}/>
                </Form.Group>
                <Form.Group controlId="formBasicChecbox" className="col-12" style={{
                    'fontSize' : '0.8em'
                  }}>
                  <label className="checkbox-container">
                    <a href="https://hellonimbly.com/terms" target="_blank" rel="noopener noreferrer" className = " text-purple" > I agree with terms and conditions.</a>
                    <input type="checkbox" name="terms" value="1"  required="required" />
                    <span className="checkmark-purple"></span>
                  </label>
                </Form.Group>
                <Form.Group className="col-12">
                  <Button variant="purple" className="nimbled pr-4 pl-4" style={{
                      borderRadius: 25
                    }} type="submit">Subscribe</Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>

        <ResponseModal open={this.state.openResponse} response={this.state.response} />
      </section>
      )
  }
}
export default ThinkBigger;
