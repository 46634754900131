import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Image
} from 'react-bootstrap';
import {Helmet} from 'react-helmet'

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var axios = require('axios');

class aboutPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      validated: false,
      industriesList: [], // Opinions
      opinionsList: [], // Opinions
      experiencesList: [], // Experiences
      teamList: [], // Team
      visible: 5,
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);

    this.loadMore = this.loadMore.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  loadMore() {
    this.setState((prev) => {
      return {visible: prev.visible + 5};
    });
  }

  componentDidMount() {
    // Team JSON
    axios.get('../Data/team.json').then(response => {
      this.setState({teamList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({validated: true});
  }

  render() {

    return (<div className="main">
      <Helmet>
        <title>Nimbly - About us</title>
        <meta name="description" content=""/>
      </Helmet>

      <Header className="header"/>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 about--header">
        <Container className="pt-5 mt-5">
          <Row>
            <Col lg={6} className="pl-lg-5">
              <h1 className="h5 mb-5 underline-left underline-white text-white">About us</h1>
              <h2 className="h1 text-white">Our Story</h2>
              <p className=" mb-5 pt-lg-4 h3 text-white" style={{'fontWeight':'500'}}>
  We believe that technology is developed to make our lives easier and to advance the potential in us.
              </p>
            </Col>
            <Col lg={6} className="pl-lg-5">
              <div className="infobox-container">
                <div className="infobox-content--bottom">
                  <h2 className="infobox-title mb-5 underline-left underline-yellow text-green">How it all started?</h2>
                  <p className="infobox-desc">
    This idea first sparks because we got enough of inefficient and burdensome manual process, insufficient system of issue follow-up and resolution, lack of visibility on key metrics overtime, and other painful processes of managing operations in multiple locations. We realised  that if we optimized technology usage, we can free up to focus on more valuable, high-impact tasks that help businesses consistently reduce errors, increase team innovation, and establish high decision quality. That's why we build Nimbly.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 mb-5">
        <Container className="">
          <Row>
            <Col lg={4} className="pl-l-5">
              <h2 className="h5 text-center text-lg-left underline-center underline-lg-left underline-yellow">What we do</h2>
              <p className=" mb-5 pt-4">
                Nimbly is a solution that transforms the old-fashioned, day-to-day operational process into a digital and much more efficient process. With Nimbly, companies are able to validate visitations, attach live photos and videos, assign and transfer report across departments, also discover issues and monitor resolution as it happens. Have access to data-driven business insights and see what's happening in your company in a bigger picture. Say no more to pen-and-paper operational audits!
              </p>
            </Col>
            <Col lg={4} className="pl-lg-5">
              <h2 className="h5 text-center text-lg-left underline-center underline-lg-left underline-yellow">Vision</h2>
              <p className=" mb-5 pt-4">
                Create a more transparent world by enabling companies to achieve visibility all the way to the last mile. We empower and bring accountability to the mobile workforce who ensure the effective implementation of the organization’s guidelines and standards.
              </p>
            </Col>
            <Col lg={4} className="pl-lg-5">
              <h2 className="h5 text-center text-lg-left underline-center underline-lg-left underline-yellow">Mision</h2>
              <p className=" mb-5 pt-4">
                Enable businesses to automate operations management processes and gain actionable insights in real-time.Gain breakthrough efficiency and operational excellence on-the-go.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 pt-lg-5 mb-5 about--bg2 d-flex align-items-center">
        <Container>
          <h2 className="h1 text-white text-center mb-4">Meet our team</h2>
        </Container>
      </section>

      <section className="pt-5 pt-lg-5 mb-5 pb-5">
        <Container className="mt-5">
          <Row>
            <Col lg={6} className="pl-lg-6 mb-5 mb-lg-0">
              <div className="d-md-flex justify-content-md-around text-center text-md-left">
                <div>
                  <figure className="pr-4">
                    <Image src="/Assets/Images/Team/[PHOTO] Daniel Hazman.png" alt="" width="180" roundedCircle="roundedCircle" />
                  </figure>
                </div>
                <div>
                  <h5 className="text-dark">Daniel Hazman</h5>
                  <h4 className="text-gray" style={{'fontSize': 'medium'}}>Co-founder & CEO</h4>
                  <p className="mt-4 mb-4 text-dark">Ex-Walmart and General Mills. Established Indonesia country office for President Clinton. Fullbright MBA Scholar.</p>
                </div>
              </div>
            </Col>
            <Col lg={6} className="pl-lg-6">
              <div className="d-md-flex justify-content-around text-center text-md-left">
                <div>
                  <figure className="pr-4">
                    <Image src="/Assets/Images/Team/[PHOTO] Yow Zi Shing.png" alt="" width="180" roundedCircle="roundedCircle" />
                  </figure>
                </div>
                <div>
                  <h5 className="text-dark">Yow Zi Shing</h5>
                  <h4 className="text-gray" style={{'fontSize': 'medium'}}>Co-founder & CTO</h4>
                  <p className="mt-4 mb-4 text-dark">Tech rebel who taught himself coding while in college. Have developed products for 4 startups in the last 5 years.</p>
                </div>
              </div>
            </Col>
          </Row>
            <Row className="mt-1 pt-1 mt-md-4 pt-md-4">
              <Col sm={12}>
                <Row className="d-flex justify-content-around team-container">
                  {this.state.teamList.slice(0, this.state.visible).map((item, index) => {
                    return (
                      <div className="text-center mt-5 team-person" key={index}>
                        <figure>
                          <Image src={item.image} alt="" width="180" roundedCircle="roundedCircle" />
                        </figure>
                        <h5 className="text-dark">{item.name}</h5>
                      </div>
                    );
                  })}
                </Row>
              </Col>
              <Col className="text-center mt-5">
                {this.state.visible < this.state.teamList.length &&
                        <Button onClick={this.loadMore} variant="outline-purple" className="nimbled">
                        Load more
                        </Button>
                }
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col lg={{ span: 6, offset: 3 }} className="mt-lg-5 pt-lg-5 text-center">
                <div className="mb-lg-5">
                  <h2 className="mb-4">We’re hungry for talents</h2>
                  <p className="mb-3">
                  Nimbly Technologies is looking for people who love technology and got that itch to deliver something cool and out-of-the box. If that sounds up your alley, the best jobs are staring at you!
                  </p>
                  <NavLink to="/careers" className="nimbled text-white pl-4 pr-4 mt-3 btn btn-purple">
                    Explore Career
                  </NavLink>
                </div>
              </Col>
            </Row>
        </Container>
      </section>

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default aboutPage;
