import React, {Component} from 'react';
import {
  Row,
  Col,
  Form,
  FormControl,
  Button
} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';

// Utilities
import Slider from "react-slick";
import BlogArticle from '../Includes/_Blog-article';

var axios = require('axios');

function NextArrow(props) {
  const {className, onClick} = props;
  return (<div className={className} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.387" height="20.221" viewBox="0 0 14.387 20.221" className="arrow">
      <g id="Grupo_68" data-name="Grupo 68" transform="translate(-5.674 -0.809)">
        <path id="Trazado_36" data-name="Trazado 36" d="M9.242,1.515l9.4,9.4-9.4,9.4" fill="none" stroke="#6642c9" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
    </svg>
  </div>);
}

function PrevArrow(props) {
  const {className, onClick} = props;
  return (<div className={className} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.387" height="20.221" viewBox="0 0 14.387 20.221" className="arrow">
      <g id="Grupo_68" data-name="Grupo 68" transform="translate(-5.674 -0.809)">
        <path id="Trazado_36" data-name="Trazado 36" d="M9.242,1.515l9.4,9.4-9.4,9.4" fill="none" stroke="#6642c9" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
    </svg>
  </div>);
}

class loginPage extends Component {
  constructor() {
    super();
    this.state = {
      validated: false,
      error: null,
      articlesList: [], // Articles
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  componentDidMount() {
    // Articles JSON
    axios.get('../Data/blog-articles.json').then(response => {
      this.setState({articlesList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({validated: true});
  }

  stripHtml(html){
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  render() {
    const {validated} = this.state;
    /*
    * Articles Slider
    */
    const articlesSlider = {
      autoplay: true,
      dots: false,
      infinite: true,
      arrows: true,
      centerMode: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider-articles',
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false
          }
        }
      ]
    };

    // articles List
    const articlesList = this.state.articlesList;
    let articlesListBlock = '';
    var link;

    if (articlesList.length > 0) {
      articlesListBlock = articlesList.map(function(obj,i) {
        link = '/blog/'+obj.slug;
        return (<div key={i} className="login-article--container">
          <BlogArticle className="login-article" image='' key={i} title={obj.title} cat={obj.cat} date={obj.date} body={obj.body} slug={link}/>
        </div>
        )
      });
    }

    return (<div className="main login">
      <Helmet>
        <title>Nimbly - login</title>
        <meta name="description" content="React JS Home"/>
      </Helmet>

        <NavLink to="/" className="login-close">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
          <g filter="url(#filter0_d)">
          <circle cx="28" cy="26" r="18" fill="white"/>
          </g>
          <path fillRule="evenodd" clipRule="evenodd" d="M28.0455 28.0182L24.122 31.943L22 29.822L25.851 25.971L22.001 22.121L24.122 20L28.0475 23.9248L31.971 20L34.093 22.121L30.242 25.972L34.092 29.822L31.971 31.943L28.0455 28.0182Z" fill="#A7A7A7"/>
          <defs>
          <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
        </NavLink>

      <section style={{'padding':'0 15px'}}>
        <Row style={{'minHeight':'100vh'}}>
          <Col lg={12} className="bg text-center align-items-center d-flex d-lg-none justify-content-center">
            <NavLink to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="187.469" height="51.018" viewBox="0 0 187.469 51.018">
              <g id="Grupo_166" data-name="Grupo 166" transform="translate(-28.796 -28.346)">
                <g id="Grupo_162" data-name="Grupo 162" transform="translate(28.796 28.346)">
                  <g id="Grupo_155" data-name="Grupo 155" transform="translate(0 11.89)">
                    <g id="Grupo_152" data-name="Grupo 152">
                      <rect id="Rectángulo_148" data-name="Rectángulo 148" width="2.362" height="33.609" fill="#fff"/>
                    </g>
                    <g id="Grupo_153" data-name="Grupo 153" transform="translate(5.216 0.592)">
                      <rect id="Rectángulo_149" data-name="Rectángulo 149" width="2.362" height="33.017" fill="#fff"/>
                    </g>
                    <g id="Grupo_154" data-name="Grupo 154" transform="translate(10.431 1.027)">
                      <rect id="Rectángulo_150" data-name="Rectángulo 150" width="2.362" height="32.582" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Grupo_156" data-name="Grupo 156" transform="translate(0 10.432)">
                    <path id="Trazado_125" data-name="Trazado 125" d="M61.706,108.916H59.344v-3.9A34.9,34.9,0,0,0,55.449,90.5C50.387,81.02,41.419,76.211,28.8,76.211V73.849c16.942,0,25,8.5,28.78,15.623a36.67,36.67,0,0,1,4.13,15.546Z" transform="translate(-28.796 -73.849)" fill="#fff"/>
                  </g>
                  <g id="Grupo_157" data-name="Grupo 157" transform="translate(0 5.216)">
                    <path id="Trazado_126" data-name="Trazado 126" d="M66.922,91.381H64.56v-3.9a41.263,41.263,0,0,0-4.511-17.13C54.136,59.144,43.621,53.46,28.8,53.46V51.1c19.691,0,29.023,9.917,33.384,18.236a42.909,42.909,0,0,1,4.742,18.152Z" transform="translate(-28.796 -51.098)" fill="#fff"/>
                  </g>
                  <g id="Grupo_158" data-name="Grupo 158" transform="translate(0)">
                    <path id="Trazado_127" data-name="Trazado 127" d="M72.138,73.845H69.775v-3.9a47.986,47.986,0,0,0-5.2-19.74,34.748,34.748,0,0,0-12.114-13.4c-6.353-4.049-14.314-6.1-23.663-6.1V28.346c9.829,0,18.233,2.188,24.979,6.5a37.1,37.1,0,0,1,12.93,14.343,49.5,49.5,0,0,1,5.432,20.756Z" transform="translate(-28.796 -28.346)" fill="#fff"/>
                  </g>
                  <g id="Grupo_159" data-name="Grupo 159" transform="translate(30.604 10.549)">
                    <path id="Trazado_128" data-name="Trazado 128" d="M175.431,74.36c-12.954,12.184-13.137,30.264-13.139,31.03v3.9h2.362v-3.893c0-.176.174-17.706,12.278-29.2Z" transform="translate(-162.292 -74.36)" fill="#fff"/>
                  </g>
                  <g id="Grupo_160" data-name="Grupo 160" transform="translate(41.01 18.924)">
                    <path id="Trazado_129" data-name="Trazado 129" d="M218.558,112.754l-1.48-1.861-.1.1c-9.156,9.118-9.292,22.03-9.293,22.579v3.9h2.362v-3.891c0-.124.149-12.412,8.512-20.823" transform="translate(-207.684 -110.893)" fill="#fff"/>
                  </g>
                  <g id="Grupo_161" data-name="Grupo 161" transform="translate(35.794 14.713)">
                    <path id="Trazado_130" data-name="Trazado 130" d="M196.193,92.525c-11.081,10.658-11.261,26.236-11.261,26.89v3.9h2.362v-3.9c0-.15.11-15.067,10.377-25.034Z" transform="translate(-184.932 -92.525)" fill="#fff"/>
                  </g>
                </g>
                <g id="Grupo_165" data-name="Grupo 165" transform="translate(87.178 37.222)">
                  <g id="Grupo_163" data-name="Grupo 163" transform="translate(0 20.827)">
                    <path id="Trazado_131" data-name="Trazado 131" d="M283.465,165.478h10.324v2.279h-3.751v11.8h-2.823v-11.8h-3.751Z" transform="translate(-283.465 -163.744)" fill="#fff"/>
                    <path id="Trazado_132" data-name="Trazado 132" d="M331.385,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.6,6.6,0,0,1-.081,1.089h-8.167a2.614,2.614,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351h3.045A5.192,5.192,0,0,1,331.385,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,328.662,182.071Z" transform="translate(-316.079 -172.896)" fill="#fff"/>
                    <path id="Trazado_133" data-name="Trazado 133" d="M384.025,177.353a4.984,4.984,0,0,1,5.263,3.952h-3.045a2.195,2.195,0,0,0-2.238-1.553c-1.573,0-2.622,1.19-2.622,3.368s1.049,3.347,2.622,3.347a2.131,2.131,0,0,0,2.238-1.533h3.045a5.1,5.1,0,0,1-5.263,3.953c-3.226,0-5.525-2.259-5.525-5.767S380.8,177.353,384.025,177.353Z" transform="translate(-356.713 -172.896)" fill="#fff"/>
                    <path id="Trazado_134" data-name="Trazado 134" d="M432.566,161.783h2.823v5.142a4.292,4.292,0,0,1,3.468-1.553c2.521,0,4.356,1.694,4.356,4.779V176.7H440.39v-6.17c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.52.968-2.52,2.763v6.17h-2.823Z" transform="translate(-398.385 -160.896)" fill="#fff"/>
                    <path id="Trazado_135" data-name="Trazado 135" d="M496.594,182.6c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.521.968-2.521,2.763v6.17H488.77V177.6h2.823v1.391a4.173,4.173,0,0,1,3.368-1.553c2.6,0,4.456,1.694,4.456,4.779v6.553h-2.823Z" transform="translate(-441.704 -172.963)" fill="#fff"/>
                    <path id="Trazado_136" data-name="Trazado 136" d="M547.678,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,547.678,188.887Zm0-2.461a2.974,2.974,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C544.874,185.338,546.165,186.427,547.678,186.427Z" transform="translate(-482.739 -172.896)" fill="#fff"/>
                    <path id="Trazado_137" data-name="Trazado 137" d="M599.445,161.783h2.823V176.7h-2.823Z" transform="translate(-527.007 -160.896)" fill="#fff"/>
                    <path id="Trazado_138" data-name="Trazado 138" d="M624.586,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,624.586,188.887Zm0-2.461a2.973,2.973,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C621.783,185.338,623.074,186.427,624.586,186.427Z" transform="translate(-542.017 -172.896)" fill="#fff"/>
                    <path id="Trazado_139" data-name="Trazado 139" d="M678.45,177.353a4.37,4.37,0,0,1,3.69,1.774v-1.593h2.843v11.252c0,3.025-1.855,5.424-5.525,5.424-3.146,0-5.344-1.573-5.626-4.134h2.8a2.627,2.627,0,0,0,2.743,1.674c1.552,0,2.762-.887,2.762-2.964v-1.734a4.406,4.406,0,0,1-3.69,1.835c-2.843,0-5.1-2.319-5.1-5.808S675.606,177.353,678.45,177.353Zm.726,2.48a2.98,2.98,0,0,0-2.944,3.247,3.053,3.053,0,0,0,2.944,3.327,3.3,3.3,0,0,0,0-6.574Z" transform="translate(-583.967 -172.896)" fill="#fff"/>
                    <path id="Trazado_140" data-name="Trazado 140" d="M732.9,159.567a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,732.9,159.567Zm.3,2.984h2.823v11.171H733.2Z" transform="translate(-629.869 -157.913)" fill="#fff"/>
                    <path id="Trazado_141" data-name="Trazado 141" d="M758.977,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.591,6.591,0,0,1-.08,1.089h-8.167a2.613,2.613,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351H764.2A5.192,5.192,0,0,1,758.977,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,756.254,182.071Z" transform="translate(-645.645 -172.896)" fill="#fff"/>
                    <path id="Trazado_142" data-name="Trazado 142" d="M810.962,188.887c-2.8,0-4.759-1.633-4.88-3.711h2.843a1.854,1.854,0,0,0,2.017,1.492c1.109,0,1.714-.5,1.714-1.149,0-2-6.311-.726-6.311-4.82,0-1.855,1.654-3.347,4.416-3.347,2.7,0,4.336,1.472,4.5,3.69h-2.7a1.664,1.664,0,0,0-1.875-1.452c-1.048,0-1.593.423-1.593,1.069,0,2.036,6.19.766,6.271,4.859C815.358,187.435,813.684,188.887,810.962,188.887Z" transform="translate(-686.272 -172.896)" fill="#fff"/>
                  </g>
                  <g id="Grupo_164" data-name="Grupo 164">
                    <path id="Trazado_143" data-name="Trazado 143" d="M295.5,74.541v14.1H292.68l-6.392-9.659v9.659h-2.823v-14.1h2.823l6.392,9.679V74.541Z" transform="translate(-283.465 -72.827)" fill="#fff"/>
                    <path id="Trazado_144" data-name="Trazado 144" d="M345.359,68.719a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,345.359,68.719Zm.3,2.984h2.823V82.874h-2.823Z" transform="translate(-331.17 -67.065)" fill="#fff"/>
                    <path id="Trazado_145" data-name="Trazado 145" d="M384.621,91.754a2.5,2.5,0,1,0-4.981,0v6.17h-2.823v-6.17a2.508,2.508,0,1,0-5,0v6.17h-2.823V86.754h2.823V88.1a4.067,4.067,0,0,1,3.287-1.512,4.275,4.275,0,0,1,3.932,2.138,4.423,4.423,0,0,1,3.831-2.138c2.682,0,4.577,1.694,4.577,4.779v6.553h-2.823Z" transform="translate(-349.386 -82.115)" fill="#fff"/>
                    <path id="Trazado_146" data-name="Trazado 146" d="M465.846,74.5c2.864,0,5.1,2.238,5.1,5.726s-2.238,5.808-5.1,5.808a4.4,4.4,0,0,1-3.69-1.774v1.593h-2.823V70.935h2.823v5.384A4.352,4.352,0,0,1,465.846,74.5Zm-.746,2.48a3.307,3.307,0,0,0,0,6.574,3.056,3.056,0,0,0,2.964-3.327A2.983,2.983,0,0,0,465.1,76.984Z" transform="translate(-419.015 -70.048)" fill="#fff"/>
                    <path id="Trazado_147" data-name="Trazado 147" d="M517.412,70.935h2.823V85.857h-2.823Z" transform="translate(-463.78 -70.048)" fill="#fff"/>
                    <path id="Trazado_148" data-name="Trazado 148" d="M543.132,87.3h3l-6.917,16.454h-3l2.419-5.565L534.158,87.3h3.166l2.884,7.8Z" transform="translate(-476.687 -82.659)" fill="#fff"/>
                  </g>
                </g>
              </g>
            </svg>
          </NavLink>
          </Col>
          <Col lg={5} className="text-center align-items-lg-center d-flex justify-content-center justify-content-lg-end">
            <div className="text-center pl-4 pr-4 login-box mr-xl-5">
              <NavLink to="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="187.469" height="51.018" viewBox="0 0 187.469 51.018" className="d-none d-lg-inline-block">
                  <g id="Grupo_166" data-name="Grupo 166" transform="translate(-28.796 -28.346)">
                    <g id="Grupo_162" data-name="Grupo 162" transform="translate(28.796 28.346)">
                      <g id="Grupo_155" data-name="Grupo 155" transform="translate(0 11.89)">
                        <g id="Grupo_152" data-name="Grupo 152">
                          <rect id="Rectángulo_148" data-name="Rectángulo 148" width="2.362" height="33.609" fill="#3cd070"/>
                        </g>
                        <g id="Grupo_153" data-name="Grupo 153" transform="translate(5.216 0.592)">
                          <rect id="Rectángulo_149" data-name="Rectángulo 149" width="2.362" height="33.017" fill="#3cd070"/>
                        </g>
                        <g id="Grupo_154" data-name="Grupo 154" transform="translate(10.431 1.027)">
                          <rect id="Rectángulo_150" data-name="Rectángulo 150" width="2.362" height="32.582" fill="#3cd070"/>
                        </g>
                      </g>
                      <g id="Grupo_156" data-name="Grupo 156" transform="translate(0 10.432)">
                        <path id="Trazado_125" data-name="Trazado 125" d="M61.706,108.916H59.344v-3.9A34.9,34.9,0,0,0,55.449,90.5C50.387,81.02,41.419,76.211,28.8,76.211V73.849c16.942,0,25,8.5,28.78,15.623a36.67,36.67,0,0,1,4.13,15.546Z" transform="translate(-28.796 -73.849)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_157" data-name="Grupo 157" transform="translate(0 5.216)">
                        <path id="Trazado_126" data-name="Trazado 126" d="M66.922,91.381H64.56v-3.9a41.263,41.263,0,0,0-4.511-17.13C54.136,59.144,43.621,53.46,28.8,53.46V51.1c19.691,0,29.023,9.917,33.384,18.236a42.909,42.909,0,0,1,4.742,18.152Z" transform="translate(-28.796 -51.098)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_158" data-name="Grupo 158" transform="translate(0)">
                        <path id="Trazado_127" data-name="Trazado 127" d="M72.138,73.845H69.775v-3.9a47.986,47.986,0,0,0-5.2-19.74,34.748,34.748,0,0,0-12.114-13.4c-6.353-4.049-14.314-6.1-23.663-6.1V28.346c9.829,0,18.233,2.188,24.979,6.5a37.1,37.1,0,0,1,12.93,14.343,49.5,49.5,0,0,1,5.432,20.756Z" transform="translate(-28.796 -28.346)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_159" data-name="Grupo 159" transform="translate(30.604 10.549)">
                        <path id="Trazado_128" data-name="Trazado 128" d="M175.431,74.36c-12.954,12.184-13.137,30.264-13.139,31.03v3.9h2.362v-3.893c0-.176.174-17.706,12.278-29.2Z" transform="translate(-162.292 -74.36)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_160" data-name="Grupo 160" transform="translate(41.01 18.924)">
                        <path id="Trazado_129" data-name="Trazado 129" d="M218.558,112.754l-1.48-1.861-.1.1c-9.156,9.118-9.292,22.03-9.293,22.579v3.9h2.362v-3.891c0-.124.149-12.412,8.512-20.823" transform="translate(-207.684 -110.893)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_161" data-name="Grupo 161" transform="translate(35.794 14.713)">
                        <path id="Trazado_130" data-name="Trazado 130" d="M196.193,92.525c-11.081,10.658-11.261,26.236-11.261,26.89v3.9h2.362v-3.9c0-.15.11-15.067,10.377-25.034Z" transform="translate(-184.932 -92.525)" fill="#3cd070"/>
                      </g>
                    </g>
                    <g id="Grupo_165" data-name="Grupo 165" transform="translate(87.178 37.222)">
                      <g id="Grupo_163" data-name="Grupo 163" transform="translate(0 20.827)">
                        <path id="Trazado_131" data-name="Trazado 131" d="M283.465,165.478h10.324v2.279h-3.751v11.8h-2.823v-11.8h-3.751Z" transform="translate(-283.465 -163.744)" fill="#3cd070"/>
                        <path id="Trazado_132" data-name="Trazado 132" d="M331.385,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.6,6.6,0,0,1-.081,1.089h-8.167a2.614,2.614,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351h3.045A5.192,5.192,0,0,1,331.385,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,328.662,182.071Z" transform="translate(-316.079 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_133" data-name="Trazado 133" d="M384.025,177.353a4.984,4.984,0,0,1,5.263,3.952h-3.045a2.195,2.195,0,0,0-2.238-1.553c-1.573,0-2.622,1.19-2.622,3.368s1.049,3.347,2.622,3.347a2.131,2.131,0,0,0,2.238-1.533h3.045a5.1,5.1,0,0,1-5.263,3.953c-3.226,0-5.525-2.259-5.525-5.767S380.8,177.353,384.025,177.353Z" transform="translate(-356.713 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_134" data-name="Trazado 134" d="M432.566,161.783h2.823v5.142a4.292,4.292,0,0,1,3.468-1.553c2.521,0,4.356,1.694,4.356,4.779V176.7H440.39v-6.17c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.52.968-2.52,2.763v6.17h-2.823Z" transform="translate(-398.385 -160.896)" fill="#3cd070"/>
                        <path id="Trazado_135" data-name="Trazado 135" d="M496.594,182.6c0-1.795-.988-2.763-2.48-2.763-1.532,0-2.521.968-2.521,2.763v6.17H488.77V177.6h2.823v1.391a4.173,4.173,0,0,1,3.368-1.553c2.6,0,4.456,1.694,4.456,4.779v6.553h-2.823Z" transform="translate(-441.704 -172.963)" fill="#3cd070"/>
                        <path id="Trazado_136" data-name="Trazado 136" d="M547.678,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,547.678,188.887Zm0-2.461a2.974,2.974,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C544.874,185.338,546.165,186.427,547.678,186.427Z" transform="translate(-482.739 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_137" data-name="Trazado 137" d="M599.445,161.783h2.823V176.7h-2.823Z" transform="translate(-527.007 -160.896)" fill="#3cd070"/>
                        <path id="Trazado_138" data-name="Trazado 138" d="M624.586,188.887a5.767,5.767,0,1,1,5.827-5.767A5.585,5.585,0,0,1,624.586,188.887Zm0-2.461a2.973,2.973,0,0,0,2.924-3.307c0-2.238-1.392-3.307-2.884-3.307s-2.843,1.069-2.843,3.307C621.783,185.338,623.074,186.427,624.586,186.427Z" transform="translate(-542.017 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_139" data-name="Trazado 139" d="M678.45,177.353a4.37,4.37,0,0,1,3.69,1.774v-1.593h2.843v11.252c0,3.025-1.855,5.424-5.525,5.424-3.146,0-5.344-1.573-5.626-4.134h2.8a2.627,2.627,0,0,0,2.743,1.674c1.552,0,2.762-.887,2.762-2.964v-1.734a4.406,4.406,0,0,1-3.69,1.835c-2.843,0-5.1-2.319-5.1-5.808S675.606,177.353,678.45,177.353Zm.726,2.48a2.98,2.98,0,0,0-2.944,3.247,3.053,3.053,0,0,0,2.944,3.327,3.3,3.3,0,0,0,0-6.574Z" transform="translate(-583.967 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_140" data-name="Trazado 140" d="M732.9,159.567a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,732.9,159.567Zm.3,2.984h2.823v11.171H733.2Z" transform="translate(-629.869 -157.913)" fill="#3cd070"/>
                        <path id="Trazado_141" data-name="Trazado 141" d="M758.977,188.887a5.411,5.411,0,0,1-5.606-5.767c0-3.529,2.3-5.767,5.606-5.767a5.244,5.244,0,0,1,5.5,5.525,6.591,6.591,0,0,1-.08,1.089h-8.167a2.613,2.613,0,0,0,2.682,2.581,2.3,2.3,0,0,0,2.238-1.351H764.2A5.192,5.192,0,0,1,758.977,188.887Zm-2.722-6.816h5.3a2.482,2.482,0,0,0-2.662-2.4A2.594,2.594,0,0,0,756.254,182.071Z" transform="translate(-645.645 -172.896)" fill="#3cd070"/>
                        <path id="Trazado_142" data-name="Trazado 142" d="M810.962,188.887c-2.8,0-4.759-1.633-4.88-3.711h2.843a1.854,1.854,0,0,0,2.017,1.492c1.109,0,1.714-.5,1.714-1.149,0-2-6.311-.726-6.311-4.82,0-1.855,1.654-3.347,4.416-3.347,2.7,0,4.336,1.472,4.5,3.69h-2.7a1.664,1.664,0,0,0-1.875-1.452c-1.048,0-1.593.423-1.593,1.069,0,2.036,6.19.766,6.271,4.859C815.358,187.435,813.684,188.887,810.962,188.887Z" transform="translate(-686.272 -172.896)" fill="#3cd070"/>
                      </g>
                      <g id="Grupo_164" data-name="Grupo 164">
                        <path id="Trazado_143" data-name="Trazado 143" d="M295.5,74.541v14.1H292.68l-6.392-9.659v9.659h-2.823v-14.1h2.823l6.392,9.679V74.541Z" transform="translate(-283.465 -72.827)" fill="#3cd070"/>
                        <path id="Trazado_144" data-name="Trazado 144" d="M345.359,68.719a1.736,1.736,0,1,1,1.734,1.653A1.656,1.656,0,0,1,345.359,68.719Zm.3,2.984h2.823V82.874h-2.823Z" transform="translate(-331.17 -67.065)" fill="#3cd070"/>
                        <path id="Trazado_145" data-name="Trazado 145" d="M384.621,91.754a2.5,2.5,0,1,0-4.981,0v6.17h-2.823v-6.17a2.508,2.508,0,1,0-5,0v6.17h-2.823V86.754h2.823V88.1a4.067,4.067,0,0,1,3.287-1.512,4.275,4.275,0,0,1,3.932,2.138,4.423,4.423,0,0,1,3.831-2.138c2.682,0,4.577,1.694,4.577,4.779v6.553h-2.823Z" transform="translate(-349.386 -82.115)" fill="#3cd070"/>
                        <path id="Trazado_146" data-name="Trazado 146" d="M465.846,74.5c2.864,0,5.1,2.238,5.1,5.726s-2.238,5.808-5.1,5.808a4.4,4.4,0,0,1-3.69-1.774v1.593h-2.823V70.935h2.823v5.384A4.352,4.352,0,0,1,465.846,74.5Zm-.746,2.48a3.307,3.307,0,0,0,0,6.574,3.056,3.056,0,0,0,2.964-3.327A2.983,2.983,0,0,0,465.1,76.984Z" transform="translate(-419.015 -70.048)" fill="#3cd070"/>
                        <path id="Trazado_147" data-name="Trazado 147" d="M517.412,70.935h2.823V85.857h-2.823Z" transform="translate(-463.78 -70.048)" fill="#3cd070"/>
                        <path id="Trazado_148" data-name="Trazado 148" d="M543.132,87.3h3l-6.917,16.454h-3l2.419-5.565L534.158,87.3h3.166l2.884,7.8Z" transform="translate(-476.687 -82.659)" fill="#3cd070"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </NavLink>
                <h2 className="text-green mt-5 pt-5">Hi, there!</h2>
                <p className="mb-4">Welcome back. If you don't have an account yet, contact our team for a demo.</p>
                <Form noValidate="noValidate" validated={validated} onSubmit={e => this.handleSubmit(e)} className="row">
                  <Form.Group className="col-md-12">
                    <FormControl type="email" name="email" placeholder="Email" aria-label="Email" aria-describedby="Email" style={{
                        borderRadius: 10,
                        height: '50px'
                      }} required="required"/>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <FormControl type="password" name="password" placeholder="Password" aria-label="Password" aria-describedby="Password" style={{
                        borderRadius: 10,
                        height: '50px'
                      }} required="required"/>
                  </Form.Group>
                  <Form.Group controlId="formBasicChecbox" className="col-md-12 d-flex align-items-center" style={{
                      'fontSize' : '0.8em'
                    }}>
                    <label className="checkbox-container">Remember me.
                      <input type="checkbox" name="remember" value="1" />
                      <span className="checkmark-yellow"></span>
                    </label>
                  </Form.Group>
                  <Form.Group className="col-md-12 text-center">
                    <a href="/" className="text-green">Oops! I forgot my password</a>
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Button variant="darkgreen" className="nimbled pr-4 pl-4 text-white" style={{
                        borderRadius: 25
                      }} type="submit">Login</Button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col lg={7} className="bg align-items-center justify-content-center p-0 p-lg-2 pt-4 pb-5 d-none d-lg-flex">
              <Row>
                <Col lg={12} className="text-center">
                  <h3 className="text-white" style={{'fontWeight': '500'}}>Latest articles</h3>
                </Col>
                <Col lg={12} className="align-items-center d-flex justify-content-center">
                  <Slider {...articlesSlider} className="pl-lg-5 pr-lg-5 mb-4">
                        {articlesListBlock}
                  </Slider>
                </Col>
              </Row>
            </Col>
          </Row>
      </section>

    </div>)
  }
}
export default loginPage;
