import React from 'react'
import { Button } from "react-bootstrap";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share';

const SocialMediaButtons = (props) => (
  <div className="d-flex justify-content-around d-sm-inline-block ml-2 mt-4 mt-sm-0 pl-4 pr-4 pl-lg-0 pr-lg-0">
    <FacebookShareButton
      url={props.url}
      quote={props.text}>
      <Button variant=""><img src='/Assets/Images/Icons/Facebook-icon.svg' alt="Share on Facebook" /></Button>
    </FacebookShareButton>

    <TwitterShareButton
      url={props.url}
      title={props.text}>
      <Button variant=""><img src='/Assets/Images/Icons/Twitter-icon.svg' alt="Share on Twitter" /></Button>
    </TwitterShareButton>

    <LinkedinShareButton
      url={props.url}
      title={props.text}>
      <Button variant=""><img src='/Assets/Images/Icons/LinkedIn-icon.svg' alt="Share on LinkedIn" /></Button>
    </LinkedinShareButton>

    <WhatsappShareButton
      url={props.url}
      title={props.text}>
      <Button variant=""><img src='/Assets/Images/Icons/Whatsapp-icon.svg' alt="Share on Whatsapp" /></Button>
    </WhatsappShareButton>
  </div>
)

export default SocialMediaButtons
