import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Accordion,
  useAccordionToggle
} from 'react-bootstrap';
import {Helmet} from 'react-helmet'

// Import Layout
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var axios = require('axios');
var elems;

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    elems = document.querySelectorAll(".card");
    [].forEach.call(elems, function(el) {
        if(el.id !== eventKey){
            el.classList.remove("active");
        }
    });
    document.getElementById(eventKey).classList.toggle("active");
  });
  return (
    <button
      type="button"
      className="btn text-green"
      onClick={decoratedOnClick}
    >
    <div className="row" >
    <div className="col-10" >
        {children}
      </div>
      <div className="col-2 text-right" >
        <div className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.387" height="20.221" viewBox="0 0 14.387 20.221" className="">
            <g id="Grupo_68" data-name="Grupo 68" transform="translate(-5.674 -0.809)">
              <path id="Trazado_36" data-name="Trazado 36" d="M9.242,1.515l9.4,9.4-9.4,9.4" fill="none" stroke="#3CD070" strokeMiterlimit="10" strokeWidth="2"/>
            </g>
          </svg>
        </div>
        </div>
        </div>
    </button>
  );
}


class faqPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      faqList: [], // Articles
      openVideo: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
     this.setState({ openVideo: true })
  }

  componentDidMount() {
    // Articles JSON
    axios.get('../Data/faq.json').then(response => {
      this.setState({faqList: response.data});
    }).catch(function(error) {
      console.log(error);
    });
  }

  stripHtml(html){
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  render() {
    /*
    Articles
    */
    const faqList = this.state.faqList;
    let faqListBlock = '';

    if (faqList.length > 0) {
      faqListBlock = faqList.map((obj, i) => {
        return (
          <Card id={i}>
            <Card.Header>
              <CustomToggle eventKey={i}>{obj.question}</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i}>
              <Card.Body className="mb-4">
                {obj.answer}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
      )
      })
    }

    return (<div className="main faq">
      <Helmet>
        <title>Nimbly - FAQ</title>
        <meta name="description" content="React JS Home"/>
      </Helmet>

      <Header className="header"/>

    <section className="pt-5 pb-4 pt-lg-5 pb-lg-5 mt-lg-5 faq--header">
      <Container className="mt-5 mt-lg-0">
        <Row>
          <Col className="pl-xl-5">
            <div className="faq-box">
              <div className="">
                <h1 className="h4 mb-5 underline-center underline-white text-white text-center">Frequently asked questions</h1>
              <h2 className="h1 text-white text-center">We're here to help</h2>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="pt-5 mt-5 pt-lg-3 mt-lg-3 mb-lg-5 pb-5">
        <Container className="pt-lg-4">
          <Row>
            <Col lg={8} className="offset-lg-2">
              <Accordion defaultActiveKey="0">
                {faqListBlock}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer open={this.state.openVideo} />
    </div>)
  }
}
export default faqPage;
